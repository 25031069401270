import axios from 'axios'

const hostname = window.location.hostname
const apiUrl = hostname === 'localhost' ? `http://${hostname}/v3/api` : `https://${hostname}/v3/api`

const Apiv3 = axios.create({ baseURL: apiUrl })

Apiv3.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
Apiv3.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
Apiv3.defaults.withCredentials = true;

export default Apiv3
