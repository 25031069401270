import Api from '../services/api/api'
import Apiv3 from '../services/api/apiv3'

export default class User {
  constructor() {
    this.user = null
    this.pessoa = null
    this.persons = []
    this.addresses = []
    this.contacts = []
    this.phones = []
    this.cells = []
    this.lastOrder = null
    this.logged = null
    this._subscribers = []
  }

  reset() {
    this.user = null
    this.pessoa = null
    this.addresses = []
    this.contacts = []
    this.phones = []
    this.cells = []
    this.logged = null
    this.lastOrder = null
  }

  subscribe(func) {
    this._subscribers.push(func)
  }

  unsubscribe(func) {
    this._subscribers = this._subscribers.filter((f) => f !== func)
  }

  notify() {
    const data = {
      user: this.user,
      pessoa: this.pessoa,
      persons: this.persons,
      addresses: this.addresses,
      contacts: this.contacts,
      lastOrder: this.lastOrder,
      orders: this.orders,
      logged: this.logged,
    }
    this._subscribers.forEach((func) => func(data))
  }

  initUser() {
    this.user = {
      id: null,
      email: null,
      email_verified_at: null,
      usuario: null,
      situacao: null,
      forgotPassword: false,
    }
  }

  async logout() {
    await Api.get(`/logout`)
    this.setUser()
    return
  }

  async sendCodeValidation(email) {
    try {
      const resp = await Api.post(`/send-code-validation`, { email })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async updateEmail(email) {
    try {
      await Api.put(`/user/email/${this.user.id}`, { email })
      await this.setUser()
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async resetPassword({ password, c_password }) {
    try {
      await Api.put(`/user/password/reset`, {
        password,
        c_password,
        email: this.user.email,
        code: this.user.verifyCode,
      })
    } catch (error) {
      throw error
    }
  }

  async updatePassword({ password, c_password }) {
    try {
      await Api.put(`/user/password/update`, { password, c_password })
    } catch (error) {
      throw error
    }
  }

  setPerson(pessoa_id, nome, tipo, doc) {
    const nPerson = new Person(pessoa_id, nome, tipo, doc)
    this.pessoa = nPerson
  }

  async updatePerson(data) {
    try {
      await Api.put(`/user/person`, { ...data })
      this.setUser()
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getUser() {
    await this.setUser()
    return this
  }

  setUserEmail(email) {
    this.user.email = email
  }

  setUserForgotPassword(value = true) {
    this.user.forgotPassword = value
  }

  setUserVerifyCode(value) {
    this.user.verifyCode = value
  }

  async setUser() {
    try {
      /* let userLocal = JSON.parse(localStorage.getItem('@dkgroup/user')); */
      this.loading = true
      let userLocal = await Api.get('/me')
      this.loading = false
      this.logged = false
      userLocal = userLocal.data

      if (userLocal?.usuario) {
        this.usuario = userLocal?.usuario
      }

      if (userLocal.user) {
        const id = userLocal.user.id
        const resp = await Api.get(`/user/${id}`)
        const pessoa = resp.data.pessoa
        const persons = resp.data.persons
        this.user = {
          id,
          email: resp.data.email,
          email_verified_at: resp.data.email_verified_at,
          usuario: resp.data.usuario,
          situacao: resp.data.situacao,
          forgotPassword: false,
        }
        this.pessoa = new Person(
          pessoa.id,
          pessoa.nome,
          pessoa.tipo,
          pessoa.fisica ? pessoa.fisica.cpf : pessoa.juridica.cnpj
        )
        this.lastOrder = resp.data.lasts_movimentacoes[0]
        this.orders = pessoa.movimentacoes
        this.addresses = []
        pessoa.endereco.forEach((addr) => {
          this.addresses.push(
            new Address(
              addr.id,
              addr.cep,
              addr.logradouro,
              addr.numero,
              addr.complemento,
              addr.bairro,
              addr.municipio,
              addr.uf,
              addr.contato
            )
          )
        })

        this.contacts = []
        this.phones = []
        this.cells = []
        pessoa.contato.forEach((contact) => {
          this.contacts.push(new Contact(contact.id, contact.contato, contact.responsavel, contact.tipo_contato_id))
          if (contact.tipo_contato_id == 1) {
            this.phones.push(new Contact(contact.id, contact.contato, contact.responsavel, contact.tipo_contato_id))
          }
          if (contact.tipo_contato_id == 3) {
            this.cells.push(new Contact(contact.id, contact.contato, contact.responsavel, contact.tipo_contato_id))
          }
        })

        this.persons = persons
        this.logged = true
        this.notify()
        return true
      } else {
        this.logged = false
        return false
      }
    } catch (error) {
      this.loading = false
      this.reset()
      this.logged = false
      return false
    }
  }

  setCreditCard(cc) {
    this.user.creditCard = cc
    this.notify()
  }

  async savePerson(person) {
    try {
      if (person.id) await Api.put(`/user/person/${person.id}`, person)
      else await Api.post(`/user/person`, person)

      await this.setUser()
      return
    } catch (error) {
      throw error
    }
  }

  async removePerson(id) {
    try {
      await Api.delete(`/user/person/${id}`)

      await this.setUser()
      return
    } catch (error) {
      throw error
    }
  }

  async saveAddress(address) {
    try {
      const nAddress = new Address()
      await nAddress.save(this.user.id, address)
      await this.setUser()
      return
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async saveContact(contact) {
    try {
      const nContact = new Contact()
      await nContact.save(contact)
      await this.setUser()
      return
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async removeAddress(address) {
    try {
      await Address.delete(address)
      await this.setUser()
      return
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getOrders(loja_id, params = { page: 1 }) {
    if (this.user) {
      try {
        const resp = await Apiv3.get(`/user/${this.user.id}/orders`, { params: { ...params, loja_id } })
        return resp.data
      } catch (error) {
        throw error
      }
    }
  }

  async getOrder({ order_id, loja_id }) {
    if (this.user) {
      try {
        const resp = await Apiv3.get(`/user/${this.user.id}/orders/${order_id}`, { params: { loja_id } })
        return resp.data
      } catch (error) {
        throw error
      }
    }
  }
}

class Person {
  constructor(id, nome, tipo, doc) {
    this.id = id
    this.nome = nome
    this.tipo = tipo
    this.doc = doc
  }
}

class Address {
  constructor(
    id = null,
    cep = null,
    logradouro = null,
    numero = null,
    complemento = null,
    bairro = null,
    municipio = null,
    uf = null,
    contato = null,
    ibge = null
  ) {
    this.id = id
    this.cep = cep
    this.logradouro = logradouro
    this.numero = numero
    this.complemento = complemento
    this.bairro = bairro
    this.municipio = municipio
    this.uf = uf
    this.ibge = ibge
    this.contato = contato
  }

  async save(user_id, address) {
    try {
      let resp = null

      if (address.id) resp = await Api.put(`/user/address/${user_id}`, address)
      else resp = await Api.post(`/user/address/${user_id}`, address)

      this.cep = resp.data.cep
      this.logradouro = resp.data.logradouro
      this.numero = resp.data.numero
      this.complemento = resp.data.complemento
      this.bairro = resp.data.bairro
      this.municipio = resp.data.municipio
      this.uf = resp.data.uf
      this.contato = resp.data.contato
      this.ibge = resp.data.ibge
      this.pessoa_id = resp.data.pessoa_id
      return
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  static async delete(address) {
    try {
      const resp = await Api.delete(`/user/address/${address.id}`)
      return resp
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

class Contact {
  constructor(id, contato, responsavel, tipo_contato_id) {
    this.id = id
    this.contato = contato
    this.responsavel = responsavel
    switch (tipo_contato_id) {
      case 1:
        this.tipo = 'phone'
        break
      case 2:
        this.tipo = 'email'
        break
      case 3:
        this.tipo = 'cell'
        break
      default:
        break
    }
  }

  async save(contact) {
    try {
      let resp = null
      if (contact.id) resp = await Api.put(`/user/contact`, contact)
      else resp = await Api.post(`/user/contact/`, contact)
      return resp
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
